@import "vt-themes.css";
@import "sidebar.css";

.ui.basic.blue.button, .ui.basic.blue.buttons .button{
    color: var(--primary-color) !important;
    box-shadow: inset 0 0 0 1px var(--primary-color) !important;
    background-color: transparent !important;
    
}

.ui.basic.blue.button:hover, .ui.basic.blue.buttons .button:hover{
    background: 0 0 !important;
    box-shadow: inset 0 0 0 1px var(--primary-color) !important;
    color: var(--primary-color) !important;
}

.ui.blue.button, .ui.blue.buttons .button{
    background-color: var(--primary-color) !important;
}

.ui.secondary.pointing.menu .active.item, .ui.secondary.pointing.menu .active.item:hover{
    border-color:var(--secondary-color) !important;
    color:var(--secondary-color) !important;
}

.ui.primary.button, .ui.primary.buttons .button,.ui.blue.label, .ui.blue.labels .label{
    background-color: var(--primary-color) !important;
    border-color:var(--primary-color) !important;;
}

.ui.primary.button:hover, .ui.primary.buttons .button:hover{
    background-color: var(--primary-color) !important;
}

.ui.blue.segment:not(.inverted){
    border-top:2px solid var(--primary-color) !important;
}

i.blue.icon,.ui.steps .step.active .title,.ui.blue.header{
    color: var(--primary-color) !important;
}

.filters .ui.basic.green.button,.filters .ui.steps .step.active .title,.filters .ui.basic.green.buttons .button,.filters .ui.basic.yellow.button,.filters .ui.basic.yellow.buttons .button,.filters .ui.basic.orange.button,.filters .ui.basic.orange.buttons .button,.filters .ui.basic.red.button,.filters .ui.basic.red.buttons .button{
    box-shadow: inset 0 0 0 1px #767676 !important;
    color: #767676 !important
}

.filters .buttons .button.active{
    color: #fff !important;
    background-color: var(--primary-color) !important;
}


.app-sidebar-open{
    width: calc(100% - var(--sidebar-width)) !important;
}

.vt-tile-row{
    margin-bottom: 30px;
}

.vt-flex{
    display: flex;
}

.vt-flex-column{
    display: flex;
    flex-direction:column;
    flex:1;
}

.vt-w-100{
    width:100%;
}

.vt-h-100{
    height:100%;
}

.vt-project-tile{
    width:30px;
    height:250px;
}

.vt-justify-center{
  justify-content:center;  
}


.vt-user-metrics-tile{
    width: 600px;
    height: 400px;
}


.vt-tile{
    min-width:250px;
    height: 250px;
    flex: 1;
    margin:10px;
}

.vt-tile-sm{
   width: 350px;
    height: 250px;
    border-radius: 4px;
    box-shadow: 2px 2px 4px 0 #0000002b;
    margin-top: 20px;
    margin-left: 20px;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.vt-tile-lg{
    width: 500px;
    height: 350px;
}

.vt-flex{
    flex-wrap:wrap;
}
.vt-tile-sm .header{
    background: linear-gradient(to bottom right, #609931, #87bc27);
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 4rem;
    color: white;
    border-radius: 4px 4px 0px 0px;
}


.vt-tile-sm .title{
    padding: 10px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: 600;
}

.vt-tile-sm .header{
    background: linear-gradient(to bottom right, #609931, #87bc27);
}


.vt-tile-sm.primary .header{
    background: var(--primary-color) !important;
}



.vt-tile-sm.secondary .header{
    background: var(--secondary-color) !important;
}



.vt-flex-grow-1{
    flex: 1 1 0px;
}

.vt-tile-sm .body .title {
    text-align: center;
    font-size: 20px;
    padding-top: 2%;
    color: #609931;
}


.vt-tile-chart{
    width:600px;
}

.vt-tile-chart.primary .header{
    background:#eee !important;
}

.vt-flex-grow-1{
    flex: 1;
}

.vt-number-tile{
    border:1px solid #eee;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 2.5rem;
    
    border-radius: 4px;
    color: white;
}

.vt-bg-tile1{
   background: rgb(80, 133, 144); 
}


.vt-bg-tile2{
   background: rgb(44, 213, 196) 
}

.vt-tile-table{
    width: 50%;
    margin:10px;
}

.vt-tile-table-project {
    width:100%;
    margin-top:10px;
}

.vt-flex-wrap{
    flex-wrap:wrap
}

.tile-title{
    text-align:center;
    font-size:1.2rem;
    font-weight: 600;
    padding:20px;
}

.linechart{
    height:450px;
}

.linechart-header canvas{
    max-height:400px !important;
}

.vt-flex-1{
    flex:1;
}

.dataDeleteModal {
    padding:20px;
}

.wsElements {
    margin-right:20px !important;
}

.ui.search .prompt {
    border-radius: .28571429rem !important;
}

.wsDiv .ui.search {
    display: inline !important;
}

.ui.input > input:focus {
    border-color: var(--secondary-color) !important;
}

.ui.input > input {
    border-color: var(--primary-color) !important;
}

.ui.input {
    border-color: var(--primary-color) !important;
}

.versionDiv {
    color: white !important;
    position: absolute;
    bottom: 10px;
    left:20%;
}

.versionWrap {
    padding-top:30px;
}

.dataSetElements button:hover {
    background-color: #861f41 !important;
    color: white !important;
}

.dataSetElements button:focus {
    background-color: #861f41 !important;
}

.ui.teal.button, .ui.teal.buttons .button {
    background-color: #861f41 !important;
    color: white !important;
}
.hideTabs .ui.pointing{
    display: none;
}