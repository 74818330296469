#app-container{
    height: 100%;
    margin: 0px;
}
#app-container #app-header{
    height: 40px;
    background-color: #171819;
    color: #fff;
}
#app-container #app-content{
  height: calc(100% - 40px);
}

#app-container #app-header,#app-container #app-content{
    padding: 0px;
}

#app-container #app-header header{
    height: 100%;
    padding: 0px 20px;
}

#app-sidebar{
    background: #fafafa;
    display: inline-table;
    /* width: 100px !important; */
  /* margin-left: -315px !important; */
}
.ui.inverted.menu .active.item{
    /*background: rgb(232, 119, 34);*/
}

.app-sidebar-open{
    width: calc(100% - 150px);
}

#app-data{
    height: 100%;
    overflow: auto;
    padding-bottom: 2%;
}

#app-content .ui.segment{
    border: 0px;
    border-radius: 0px;
}

#app-title{
 font-weight: 600;   
}

#sidebar-toggle{
  margin-right: 10px;  
}


#app-sidebar .menu-item,#app-sidebar .menu-item .dropdown{
    padding:0px !important;
}

#app-sidebar .menu-item .dropdown{
    /*color:#c64600;*/
    background:none;
    font-size: 0.9rem !important;
}

#app-sidebar .menu-item i.mini{
    margin:0 1.2em 0 0;
}



#app-sidebar .menu-item.active,#app-sidebar .menu-item:hover,#app-sidebar .menu-item .dropdown:hover{
    background:#c64600;
}
#app-sidebar .menu-item:hover,#app-sidebar .menu-item .dropdown:hover{
  color: #FFFFFF; 
}




#app-sidebar .menu-item.active .dropdown{
    color:#fff !important;
}


.home .filters .button{
    padding: 8px;
    font-size: 0.8rem;
}


#app-sidebar .menu-item, #app-sidebar .menu-item .active .dropdown{
    margin:1px 0px;
}

.project-admin-dropdown.disable-close .delete.icon{
 display: none;   
}